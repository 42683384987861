export enum DcEvent {
  ChatSubmit = "Chat Submit",
  ChatSubmitSuccess = "Chat Submit Success",
  ChatSubmitError = "Chat Submit Error",
  ChatSubmitRetry = "Chat Submit Retry",
  DocumentAdd = "Document Add",
  DocumentAddSuccess = "Document Add Success",
  DocumentAddError = "Document Add Error",
  ChatbotCreate = "Chatbot Create",
  ChatbotCreateSuccess = "Chatbot Create Success",
  ChatbotCreateError = "Chatbot Create Error",
  ChatbotEdit = "Chatbot Edit",
  ChatbotEditSuccess = "Chatbot Edit Success",
  ChatbotEditError = "Chatbot Edit Error",
  OnboardingActionUsed = "Onboarding Action Used",
}
