import { type CompletionModel, DcAnswerLength, type DcChatbot, QuestionSuggestionMethod, type ReqTypes } from "docuchatcommontypes";
import type { ChatbotFormData } from "~/types/form";

export function getDefaultChatbotFormData(): ChatbotFormData {
  return {
    // Essentials
    name: "",
    documents: [],

    // AI settings
    aiModel: getDefaultAiModel(),
    avatarExisting: [],
    temperature: 0.1,
    customInstructions: "",
    replaceSystemPrompt: false,
    autoAddContext: false,
    semanticSearchRatio: 0.7,
    reranking: false,
    answerLength: { id: DcAnswerLength.Medium },

    // Appearance
    avatarNew: [],
    organizationLogoExisting: [],
    description: "",
    comments: "",
    welcomeMessage: "",
    conversationStarters: "",
    autogenerateConversationStarters: true,
    questionPlaceholder: "",

    // Citation
    citeSources: true,
    allowAccessToDocuments: false,

    // White labeling
    organizationLogoNew: [],
    themeColor: "",
    themeColorDark: "",
    callToActionLabel: "",
    callToActionLink: "",
    pageTitle: "",
    userInstructions: "",
  };
}

export async function chatbotFormToReqBody(f: ChatbotFormData): Promise<ReqTypes.CreateChatBotRequest> {
  let avatarBase64: string | undefined;
  if (f.avatarNew.length)
    avatarBase64 = await fileToBase64(f.avatarNew[0]);
  else if (!f.avatarExisting.length)
    avatarBase64 = "";

  let logoBase64: string | undefined;
  if (f.organizationLogoNew.length)
    logoBase64 = await fileToBase64(f.organizationLogoNew[0]);
  else if (!f.organizationLogoExisting.length)
    logoBase64 = "";

  return {
    // Essentials
    name: f.name,
    documentGroups: [{
      name: "default",
      documentIds: f.documents.map(doc => doc.id),
    }],

    // AI settings
    temperature: f.temperature,
    answers: {
      model: f.aiModel.id as CompletionModel,
      customModelInstructions: f.customInstructions,
      replaceSystemPrompt: f.replaceSystemPrompt,
      autoAddContext: f.autoAddContext,
      semanticRate: f.semanticSearchRatio,
      rerankingEnabled: f.reranking,
      answerLength: f.answerLength.id as DcAnswerLength,

      // Citation
      citeSources: f.citeSources,
      enableAccessToSources: f.allowAccessToDocuments,

      // Appearance
      firstMessage: f.welcomeMessage,
      customSuggestedQuestions: f.conversationStarters.trim().split("\n").filter(Boolean),
      questionSuggestionMethod: f.autogenerateConversationStarters ? QuestionSuggestionMethod.AutoGenerate : QuestionSuggestionMethod.Custom,
      questionPlaceHolder: f.questionPlaceholder,
    },

    avatarBase64,
    avatarExtension: f.avatarNew[0]?.type === "image/png" ? "png" : "jpg",
    description: f.description,
    adminNotes: f.comments,

    // White labeling
    whiteLabel: {
      logoBase64,
      logoExtension: f.organizationLogoNew[0]?.type === "image/png" ? "png" : "jpg",
      color: f.themeColor,
      darkModeColor: f.themeColorDark,
      ctaUrlText: f.callToActionLabel,
      ctaUrl: f.callToActionLink,
      pageTitle: f.pageTitle,
      disclaimer: f.userInstructions,

      // Deprecated
      enabled: true,
      ctaDescriptionText: "",
    },

    limits: {
      enabled: false,
      monthlyMaxQuestions: 0,
    },

    sharing: {
      link: {
        enabled: false,
        publicListingCategory: [],
        publicListingCreatedBy: "",
        publicListingEnabled: false,
      },
      embed: {
        enabled: false,
        domains: [],
      },
      team: {
        enabled: false,
      },
    },
  };
}

export function chatbotToChatbotForm(c: DcChatbot): ChatbotFormData {
  return {
    name: c.name,
    documents: [...c.documentGroups[0].documents],

    aiModel: { id: c.answers.model },
    temperature: c.temperature ?? 0.1,
    customInstructions: c.answers.customModelInstructions ?? "",
    replaceSystemPrompt: c.answers.replaceSystemPrompt ?? false,
    semanticSearchRatio: c.answers.semanticRate ?? 0.7,
    autoAddContext: c.answers.autoAddContext ?? false,
    reranking: c.answers.rerankingEnabled ?? false,
    answerLength: { id: c.answers.answerLength ?? DcAnswerLength.Medium },

    avatarNew: [],
    avatarExisting: c.avatarUrl ? [{ id: "avatar", name: c.avatarUrl }] : [],
    description: c.description ?? "",
    comments: c.adminNotes ?? "",
    welcomeMessage: c.answers.firstMessage ?? "",
    conversationStarters: c.answers.suggestedQuestions?.join("\n") ?? "",
    autogenerateConversationStarters: c.answers.questionSuggestionMethod === QuestionSuggestionMethod.AutoGenerate,
    questionPlaceholder: c.answers.questionPlaceHolder ?? "",

    citeSources: c.answers.citeSources ?? false,
    allowAccessToDocuments: c.answers.enableAccessToSources ?? false,

    organizationLogoNew: [],
    organizationLogoExisting: c.whiteLabel.logoUrl ? [{ id: "logo", name: c.whiteLabel.logoUrl }] : [],
    themeColor: c.whiteLabel.color ?? "",
    themeColorDark: c.whiteLabel.darkModeColor ?? "",
    callToActionLabel: c.whiteLabel.ctaUrlText ?? "",
    callToActionLink: c.whiteLabel.ctaUrl ?? "",
    pageTitle: c.whiteLabel.pageTitle ?? "",
    userInstructions: c.whiteLabel.disclaimer ?? "",
  };
}
